import { navRoutes } from "page_routes"
import api from "service/api"
import { TestPrepStagesDto } from "components/NavigationModal/interface"

export enum NavigationSegment {
  IELTS = "IELTS",
  COUNSELLING = "COUNSELLING",
}

export const navigationCardData = [
  {
    heading: "Study Abroad Counselling",
    subHeading: "Speak with an education counsellor",
    rederectIcon: `${process.env.CDN_URL}/assets/images/ielts/right_open_arrow.svg`,
    image: `${process.env.CDN_URL}/assets/images/graduation_hat.png`,
    redirectingLink: navRoutes.PROFILE,
    navigationSegment: NavigationSegment.COUNSELLING,
  },
  {
    heading: "Test Preparation",
    subHeading: "Prepare for IELTS, PTE, SAT and get expert guidance.",
    rederectIcon: `${process.env.CDN_URL}/assets/images/ielts/right_open_arrow.svg`,
    image: `${process.env.CDN_URL}/assets/icons/test_prep.png`,
    redirectingLink: navRoutes.IELTS_STUDENT_DASHBOARD,
    navigationSegment: NavigationSegment.IELTS,
  },
]

export const getNavlinks = (testPrepUserStages: TestPrepStagesDto) => {
  const navLinks = {
    ieltsNavLink: "/test-prep/dashboard",
    pteNavLink: "/pte-prep",
    satNavLink: "/sat-pages/sat-sem-india-lp",
  }
  if (testPrepUserStages.pteLivePaid || testPrepUserStages.pteLiyPaid) {
    navLinks.pteNavLink = "/test-prep/pte-exam/dashboard"
  }
  if (testPrepUserStages.satLivePaid) {
    navLinks.satNavLink = "/test-prep/dashboard"
  }

  return navLinks
}

export const ieltsExamsData = (testPrepUserStages: TestPrepStagesDto) => {
  const { ieltsNavLink, pteNavLink, satNavLink } =
    getNavlinks(testPrepUserStages)

  return [
    {
      icon: `${process.env.CDN_URL}/assets/icons/ielts_icon.svg`,
      text: "IELTS",
      navLink: ieltsNavLink,
      contentName: "LS IELTS Exam Prep",
    },
    {
      icon: `${process.env.CDN_URL}/assets/icons/pte_icon.svg`,
      text: "PTE",
      navLink: pteNavLink,
      contentName: "LS PTE Exam Prep",
    },
    {
      icon: `${process.env.CDN_URL}/assets/icons/sat_icon.svg`,
      text: "SAT",
      navLink: satNavLink,
      contentName: "LS SAT Exam Prep",
    },
  ]
}

export const getTestPrepStage = async () => {
  try {
    const res = await api.get(`/api/scholarRoute?path=user/test-prep/stages`)
    if (res?.data?.success) {
      return res?.data?.data
    } else {
      return null
    }
  } catch (err) {
    console.log(err)
    return null
  }
}
