import Image from "next/image"
import React, { FunctionComponent } from "react"
import { NavigationSegment } from "components/NavigationModal/DataMapper"

interface Props {
  heading: string
  subHeading: string
  rederectIcon: string
  image: string
  handleClick: (
    heading: string,
    redirectingLink: string,
    navigationSegment: NavigationSegment,
  ) => void
  redirectingLink: string
  navigationSegment: NavigationSegment
}
const NavigationCard: FunctionComponent<Props> = ({
  heading,
  image,
  rederectIcon,
  subHeading,
  handleClick,
  redirectingLink,
  navigationSegment,
}) => {
  return (
    <div
      className="w-full p-6 rounded-2xl cursor-pointer border border-[#EEEEF2] bg-[linear-gradient(180deg,#FDFDFD_0%,#FFF_46.35%,#FFF_100%)] shadow-[0px_12px_16px_-4px_rgba(16,24,40,0.08),0px_4px_6px_-2px_rgba(16,24,40,0.03)] h-full relative"
      onClick={() => handleClick(heading, redirectingLink, navigationSegment)}
    >
      <div className="flex justify-between items-end md:gap-3 h-full z-10 relative">
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-2">
            <p className="md:text-xl font-semibold">{heading}</p>
            <p className="text-sm md:text-base text-[#8B8AA3] max-w-[230px]">
              {subHeading}
            </p>
          </div>
          <div className="bg-[#FBFCFE] p-1 md:p-2 flex items-center justify-center rounded w-fit border border-[#EEEEF2] shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)]">
            <Image src={rederectIcon} height={16} width={16} alt="arrow" />
          </div>
        </div>
        <div className="mb-[-30px] mr-[-10px] md:mb-[-10px] md:mr-0">
          <Image
            src={image}
            height={150}
            width={150}
            alt="arrow"
            quality={100}
          />
        </div>
      </div>
      <Image
        src={`${process.env.CDN_URL}/assets/images/ielts/bg_grid.svg`}
        layout="fill"
        objectFit="contain"
        objectPosition="right"
        alt="grid"
      />
    </div>
  )
}

export default NavigationCard
