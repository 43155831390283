import Layout from "components/common/Layout"
import withAuth, { AuthProps } from "components/decorator/WithAuth"
import { commonAccordionData } from "components/LSRevamp/constant"
import LandingHero from "components/LSRevamp/landingPage/LandingHero"
import LongFormContent from "components/LSRevamp/landingPage/LongFormContent"
import { AccordionData } from "components/LSRevamp/LSRevampInterfaces"
import { getEventsOnServerSide } from "components/LSRevamp/utils"
import { NextPage } from "next"
import dynamic from "next/dynamic"
import Head from "next/head"
import { useEffect } from "react"
import { trackClick, trackPage } from "scripts/segment"
import { pageName } from "utils/events-utils"
import useLocation from "hooks/login/useLocation"
import { CountryCode } from "DataMapper/CountryCode"
import OrganizationSchema from "seo/OrganizationSchema"
import NavigationModalWrapper from "components/NavigationModal/NavigationModalWrapper"

const EventSectionDynamic = dynamic(
  () => import("components/LSRevamp/landingPage/EventSection"),
  { loading: () => <p>...</p> },
)
const SocialLinksSectionDynamic = dynamic(
  () => import("components/LSRevamp/common/SocialLinksSection"),
  { loading: () => <p>...</p> },
)
const GuidesSectionDynamic = dynamic(
  () => import("components/LSRevamp/landingPage/GuidesSection"),
  { loading: () => <p>...</p> },
)
const IconLinksDynamic = dynamic(
  () => import("components/LSRevamp/landingPage/IconLinks"),
  { loading: () => <p>...</p> },
)
const FAQAccordionDynamic = dynamic(
  () => import("components/LSRevamp/common/FAQAccordion"),
  { loading: () => <p>...</p> },
)
const LeapInNewsSectionDynamic = dynamic(
  () => import("components/LSRevamp/common/LeapInNewsSection"),
  { loading: () => <p>...</p> },
)
const ReadyToTakeLeapDynamic = dynamic(
  () => import("components/LSRevamp/common/ReadyToTakeLeapBanner"),
  { loading: () => <p>...</p> },
)
const VideoTestimonials = dynamic(
  () => import("components/LSRevamp/VideoTestimonials"),
  { loading: () => <p>...</p> },
)
const InfluencersSectionDynamic = dynamic(
  () => import("components/LSRevamp/landingPage/InfluencersSection"),
  { loading: () => <p>...</p> },
)

type Props = AuthProps & {
  events: Array<any>
}

const HomePage: NextPage<Props> = ({ events, auth }) => {
  const { userCountry } = useLocation()

  useEffect(() => {
    trackPage(pageName.LeapScholarHomePage)
  }, [])

  useEffect(() => {
    if (userCountry !== CountryCode.INDIA) {
      //   @todo - add redirect
    }
  }, [userCountry])

  return (
    <>
      <Head>
        <title>
          Study Abroad - Universities, Courses, Exams, Free Counselling &
          Scholarships
        </title>
        <meta
          name="description"
          content="Want to Study Abroad? LeapScholar provides Personalized Guidance and information on top universities, courses, IELTS Coaching, Financial Help and Visa Assurance. Get Counselling and Mentorship for your Study abroad dream Today!"
        />
        <meta
          name="keywords"
          content="Study abroad, study overseas, overseas education, higher education in abroad, study abroad programs, study abroad colleges, study abroad courses, International studies"
        />
        <meta
          property="og:image"
          content="https://leapassets.s3.ap-south-1.amazonaws.com/ielts-recording/1619511191304-logo@2x_(1)_(1).png?tr=h-800,c-force"
        />

        <OrganizationSchema />

        <script
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              "@context": "https://schema.org",
              "@type": "FAQPage",
              mainEntity: commonAccordionData.map((el: AccordionData) => {
                return {
                  "@type": "Question",
                  name: el.title,
                  acceptedAnswer: {
                    "@type": "Answer",
                    text: el.content,
                  },
                }
              }),
            }),
          }}
          type="application/ld+json"
        />
      </Head>
      <Layout>

      <div className='bg-primary text-center text-white p-3 font-semibold sticky'>
        We've raised $65M in Series E funding to fulfil study abroad dreams worldwide 🎉
      </div>

        <div className="relative md:h-[570px] h-[910px] ">
          <LandingHero />
        </div>

        <div className="relative mx-auto">
          <IconLinksDynamic />
          <VideoTestimonials />
          <InfluencersSectionDynamic />
          {events && events.length > 0 && (
            <EventSectionDynamic
              events={events}
              trackingEvent={() =>
                trackClick(pageName.LeapScholarHomePage, {
                  contentName: "Book a free Counselling Session",
                  widgetName: "Masterclass Fold",
                  widgetFormat: "Banner",
                  contentFormat: "Button",
                })
              }
            />
          )}
          <GuidesSectionDynamic />
          <LeapInNewsSectionDynamic />
          <SocialLinksSectionDynamic />
          <LongFormContent />
          <FAQAccordionDynamic
            data={commonAccordionData}
            trackingEvent={() =>
              trackClick(pageName.LeapScholarHomePage, {
                contentName: "Talk to an Expert",
                widgetName: "FAQ",
                widgetFormat: "Banner",
                contentFormat: "Button",
              })
            }
          />
          <ReadyToTakeLeapDynamic
            content="Connect with India's finest counsellors and biggest study abroad community. "
            header="Ready to take the Leap ?"
            hasTwoButtons={false}
            buttonText="Talk to a Counsellor"
            trackingEvent={() => {
              trackClick(pageName.LeapScholarHomePage, {
                contentName: "Talk to a Counsellor",
                widgetName: "Bottom Banner",
                widgetFormat: "Banner",
                contentFormat: "Button",
              })
            }}
          />
        </div>
        {/* <OnPageTofuNudgeButton addedPageName={pageName.LeapScholarHomePage} /> */}
        <NavigationModalWrapper />
      </Layout>
    </>
  )
}

HomePage.getInitialProps = async (ctx): Promise<Props> => {
  const isServer = !!ctx.req

  try {
    const finalData = await getEventsOnServerSide(isServer)

    const sortedDetails = () =>
      finalData?.sort((a: any, b: any) => {
        return (new Date(a.date) as any) - (new Date(b.date) as any)
      })
    const sortedData = sortedDetails()

    const filteredFeatureData = sortedData.filter((item: any) => {
      const eventTags = item.event_tags_v2
      for (let i = 0; i < eventTags.length; i += 1) {
        if (
          eventTags[i].tag?.type === "others" &&
          eventTags[i].tag.Name === "Featured"
        ) {
          return true
        }
      }

      return false
    })

    // filter all events with tag added as featured and sort based on most upcoming date
    // if above results < 3 items then add remaining based on most upcoming date
    if (filteredFeatureData.length >= 3) {
      return {
        events: filteredFeatureData.slice(0, 3),
      }
    } else {
      return {
        events: [...filteredFeatureData, ...sortedData].slice(0, 3),
      }
    }
  } catch (err: any) {
    return {
      events: [],
    }
  }
}

export default withAuth(HomePage, false)
