import React, { FunctionComponent, useEffect } from "react"
import Popup from "components/Profile/IeltsProfile/commons/Popup"
import NavigationCard from "components/NavigationModal/NavigationCard"
import {
  navigationCardData,
  NavigationSegment,
} from "components/NavigationModal/DataMapper"
import Image from "next/image"
import useDevice from "hooks/useDevice"
import { trackEventAsync, trackPageV2 } from "scripts/segment"

interface Props {
  isFormModalOpen: boolean
  handleModalOpen: (isOpen: boolean) => void
  handleIeltsExamModal: (isOpen: boolean) => void
}
const NavigationModal: FunctionComponent<Props> = ({
  isFormModalOpen,
  handleModalOpen,
  handleIeltsExamModal,
}) => {
  const { isMobile } = useDevice()

  const handleSegmentEvent = async (heading: string) => {
    await trackEventAsync("Click", {
      contentName: heading,
      widgetName: "Landing Page Redirection Modal",
      contentFormat: "Card",
      widgetFormat: "Modal",
      pageName: "Landing Page Redirection Modal Page",
    })
  }

  const handleClick = (
    heading: string,
    redirectingLink: string,
    navigationSegment: NavigationSegment,
  ) => {
    if (navigationSegment === NavigationSegment.IELTS) {
      handleModalOpen(false)
      handleIeltsExamModal(true)
      handleSegmentEvent(heading)
    } else if (redirectingLink) {
      handleSegmentEvent(heading)
      window.open(redirectingLink, "_self")
    }
  }

  useEffect(() => {
    trackPageV2("Landing Page Redirection Modal Page")
  }, [])

  return (
    <Popup isModalOpen={isFormModalOpen} handleClose={() => {}}>
      <div className="z-[101] md:w-fit bg-[#F5F5F7] w-full fixed left-0 bottom-0 rounded-t-xl  md:min-w-[563px] md:rounded-xl md:relative md:h-auto m-auto animate-slideUp md:animate-none">
        <div className="pt-6 px-3 pb-8 md:px-8 md:pb-10">
          <div className="flex flex-col items-center w-full">
            <p className="text-xl font-semibold">What do you want to explore</p>
          </div>
          <div className="flex flex-col md:grid grid-cols-2 items-center gap-6 w-full mt-6">
            {navigationCardData.map((card, index) => (
              <NavigationCard {...card} key={index} handleClick={handleClick} />
            ))}
          </div>
        </div>
        <div
          className="absolute top-[-40px] right-16 md:top-24 md:right-24 w-fit cursor-pointer"
          onClick={async () => {
            handleModalOpen(false)
            await trackEventAsync("Click", {
              contentName: "Skip",
              widgetName: "Landing Page Redirection Modal",
              contentFormat: "Button",
              widgetFormat: "Modal",
              pageName: "Landing Page Redirection Modal Page",
            })
          }}
        >
          {isMobile ? (
            <div className="bg-white rounded-[40px] flex items-center justify-center p-1">
              <Image
                src={`${process.env.CDN_URL}/assets/icons/scholarship/crossed.svg`}
                height={20}
                width={20}
                alt="cross"
              />
            </div>
          ) : (
            <Image
              src={`${process.env.CDN_URL}/assets/images/ielts/gray_cross.svg`}
              height={32}
              width={32}
              alt="cross"
            />
          )}
        </div>
      </div>
    </Popup>
  )
}

export default NavigationModal
