import React, { FunctionComponent, useEffect, useState } from "react"
import NavigationModal from "components/NavigationModal"
import useLocation from "hooks/login/useLocation"
import { checkIfMENAUser } from "utils/country_utils"
import { useSelector } from "react-redux"
import { State } from "Interfaces/storeSchema"
import IeltsExamsModal from "components/NavigationModal/IeltsExamsModal"
import {
  getTestPrepStage,
  ieltsExamsData,
} from "components/NavigationModal/DataMapper"
import { TestPrepStagesDto } from "components/NavigationModal/interface"

const NavigationModalWrapper: FunctionComponent = () => {
  const [isNaviagtionModalOpen, setIsNaviagtionModalOpen] =
    useState<boolean>(false)
  const auth = useSelector((state: State) => state.auth.auth)
  const { userCountry } = useLocation()
  const [isIeltsExamModalOpen, setIsIeltsExamModalOpen] =
    useState<boolean>(false)

  const [testPrepUserStages, setTestPrepUserSatges] =
    useState<TestPrepStagesDto>({
      ieltsLiyPaid: false,
      ieltsLivePaid: false,
      pteLiyPaid: false,
      pteLivePaid: false,
      satLivePaid: false,
    })

  const handleRedirectionModal = (isOpen: boolean) => {
    setIsNaviagtionModalOpen(isOpen)
  }

  const handleIeltsExamModal = (isOpen: boolean) => {
    setIsIeltsExamModalOpen(isOpen)
  }

  const handleTestPrepUserStage = async () => {
    const res = await getTestPrepStage()
    if (res) {
      setTestPrepUserSatges(res)
    }
  }

  useEffect(() => {
    if (auth?.isValid && !checkIfMENAUser(userCountry)) {
      setIsNaviagtionModalOpen(true)
      handleTestPrepUserStage()
    }
  }, [auth?.isValid])

  return (
    <div>
      {isNaviagtionModalOpen && (
        <NavigationModal
          isFormModalOpen={isNaviagtionModalOpen}
          handleModalOpen={handleRedirectionModal}
          handleIeltsExamModal={handleIeltsExamModal}
        />
      )}
      {isIeltsExamModalOpen && (
        <IeltsExamsModal
          isIeltsExamModalOpen={isIeltsExamModalOpen}
          ieltsExamsdata={ieltsExamsData(testPrepUserStages)}
          handleModalOpen={handleRedirectionModal}
          handleIeltsExamModal={handleIeltsExamModal}
        />
      )}
    </div>
  )
}

export default NavigationModalWrapper
