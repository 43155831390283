import React, { FunctionComponent } from "react"
import Popup from "components/Profile/IeltsProfile/commons/Popup"
import Image from "next/image"
import useDevice from "hooks/useDevice"
import { trackEventAsync } from "scripts/segment"

interface Props {
  isIeltsExamModalOpen: boolean
  ieltsExamsdata: Array<{
    icon: string
    text: string
    navLink: string
    contentName: string
  }>
  handleModalOpen: (isOpen: boolean) => void
  handleIeltsExamModal: (isOpen: boolean) => void
}

const IeltsExamsModal: FunctionComponent<Props> = ({
  ieltsExamsdata,
  handleModalOpen,
  handleIeltsExamModal,
  isIeltsExamModalOpen,
}) => {
  const IS_MULTIPLE_OF_3 = ieltsExamsdata.length % 3 === 0
  const { isMobile } = useDevice()

  return (
    <Popup isModalOpen={isIeltsExamModalOpen} handleClose={() => {}}>
      <div className="z-[101] md:w-fit w-full fixed left-0 bottom-0 md:relative md:h-auto m-auto animate-slideUp md:animate-none">
        <div className="p-6 bg-[#F5F5F7] rounded-t-xl md:rounded-2xl border-[5px] border-white shadow-[0px_4px_6px_0px_rgba(65,52,146,0.06),0px_4px_12px_0px)_rgba(65,52,146,0.12)]">
          <div className="flex justify-between mb-6">
            <Image
              src={`${process.env.CDN_URL}/assets/icons/arrow.svg`}
              height={6}
              width={6}
              alt="arrow"
              className="brightness-0 cursor-pointer"
              onClick={() => {
                handleModalOpen(true)
                handleIeltsExamModal(false)
              }}
            />
            <p className="text-xl font-semibold">Choose Exam</p>
            {!isMobile ? (
              <Image
                src={`${process.env.CDN_URL}/assets/images/ielts/gray_cross.svg`}
                height={20}
                width={20}
                alt="cross"
                onClick={() => handleIeltsExamModal(false)}
                className="cursor-pointer"
              />
            ) : (
              <div></div>
            )}
          </div>
          <div
            className={`grid ${
              IS_MULTIPLE_OF_3 ? "grid-cols-3" : "grid-cols-2"
            } gap-4`}
          >
            {ieltsExamsdata.map(({ icon, text, navLink, contentName }) => (
              <div
                className="md:min-w-[144px] hover:scale-105 transition-all flex flex-col gap-4 items-center justify-center bg-white p-3 md:p-6 rounded-xl shadow-[0px_12px_16px_-4px_rgba(16,24,40,0.08),0px_4px_6px_-2px_rgba(16,24,40,0.03)] cursor-pointer"
                onClick={async () => {
                  await trackEventAsync("Click", {
                    contentName: contentName,
                  })
                  window.open(navLink, "_self")
                }}
              >
                <Image src={icon} height={67} width={67} alt="arrow" />
                <p className="font-semibold text-[#212121] text-sm md:text-base">
                  {text}
                </p>
              </div>
            ))}
          </div>
        </div>
        {isMobile && (
          <div className="bg-white rounded-[40px] flex items-center justify-center p-1 absolute -top-40 right-10">
            <Image
              src={`${process.env.CDN_URL}/assets/icons/scholarship/crossed.svg`}
              height={20}
              width={20}
              alt="cross"
              onClick={() => handleIeltsExamModal(false)}
            />
          </div>
        )}
      </div>
    </Popup>
  )
}

export default IeltsExamsModal
